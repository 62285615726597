import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    icon: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool
}

const defaultProps = {
    icon: '',
    value: '',
    color: '',
    disabled: false
}

const Chip = ({
    className,
    icon,
    color,
    value,
    disabled,
    children
}) => {
    const classes = classNames(
        'md-chip',
        className
    );

    return (
        <div
            className={classes}
            disabled={disabled}
        >
            {
                icon && <div className="md-chip-icon">{icon}</div>
            }
            {value}
        </div>
    );
}

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

export default Chip;