import React from 'react';
import classNames from 'classnames';

import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Chip from '../elements/Chip';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Some of the projects you might see...',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Video Game
                </div>
                <h3 className="mt-0 mb-12">
                  VOID
                </h3>
                <p className="m-0">
                  VOID is an adventure, mystery game that follows a group of teenagers in their journey and discovery of the greatest conspiracy in their town,
                  Decisions made in the game would effect the story and relationships resulting in multiple endings.
                </p>
                <div className="md-chips">
                  <Chip icon="1" value="Artist"/>
                  <Chip icon="3" value="Voice Actors" />
                  <Chip icon="2" value="Voice Actresses" />
                  <Chip icon="1" value="Music Producer" />
                  <Chip icon="1" value="Game Developer" />
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Title of game. VOID."
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Animation
                </div>
                <h3 className="mt-0 mb-12">
                  Sunflower
                </h3>
                <p className="m-0">
                  You follow the story of a young girl named Isabella who lives with her mother in section 8 housing.
                  Isabella has aspirations to become an astronaut, however, obstacles lay infront of her that prevent her from achieving her dreams.
                </p>
                <div className="md-chips">
                  <Chip icon="1" value="Animator"/>
                  <Chip icon="2" value="Voice Actors" />
                  <Chip icon="2" value="Voice Actresses" />
                  <Chip icon="1" value="Music Producer" />
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Young girl reaching for astronaut helmet. Art by @nillestudio."
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Board game
                </div>
                <h3 className="mt-0 mb-12">
                  Mimick Me
                </h3>
                <p className="m-0">
                  A unique game where players must discretely carry out actions displayed on their card during their turn.
                  When it's the next players turn, they must attempt to correctly guess the previous players actions, incorporate it
                  within their own action and making ...
                </p>
                <div className="md-chips">
                  <Chip icon="1" value="Designer"/>
                  <Chip icon="1" value="Artist" />
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Blueprint of card game."
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;