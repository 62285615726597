import React, { createContext, useContext, useState } from 'react';

export const ThemeContext = createContext(undefined);

export const ThemeContextProvider = ({ children }) => {
    const [theme, setTheme] = useState('light');

    function toggleTheme() {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    }

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error('useThemeContext must be used within ThemeContextProvider');
    }

    return context;
}