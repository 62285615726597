import { initializeApp } from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyA8uIoeBsTMhBvsymcG9hX83HJl1Ukm9Dk",
    authDomain: "collab-2b93e.firebaseapp.com",
    databaseURL: "https://collab-2b93e.firebaseio.com",
    projectId: "collab-2b93e",
    storageBucket: "collab-2b93e.appspot.com",
    messagingSenderId: "118139392224",
    appId: "1:118139392224:web:536942191b04ec9f5f4085",
    measurementId: "G-QMSNDRJLWD"
};

const firebase = initializeApp(firebaseConfig);

export const firestore = firebase.firestore();